/* eslint-disable */
import React, { useRef } from "react"
import Title from "../../Title"
import styles from "../../../css/updateplan.module.css"
import Utils from "../../../utils"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { navigate } from "gatsby"
import {entropyToMnemonic} from "bip39"


class UpdatePlan extends React.Component {
  constructor() {
    super();
    this.state = {
      orgs: [],
      active_org: {name: "loading"},
      subscribed: [],
      unsubscribed: [],
      all_subscriptions: {},
    };
  }
  handleChange(e) {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }
  handlePersistedChange(e) {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
    if(typeof window !== "undefined") {
      window.localStorage.setItem(e.target.name, JSON.stringify(state[e.target.name]));
    }
  }
  handleToggle(e) {
    let state = {};
    state[e.target.name] = !this.state[e.target.name];
    this.setState(state)
    if(typeof window !== "undefined") {
      window.localStorage.setItem(e.target.name, JSON.stringify(state[e.target.name]));
    }
  }
  componentDidMount() {
    fetch("/api/account").then((response) => {
      if(!response.ok) {
        navigate("/login/");
        throw "Not logged in"
      } else {
        return response.json()
      }
    }).then((result) => {
      let state = {orgs: result.orgs};
      this.setState(state);
      if(result.orgs.length > 0) {
        return this.setActiveOrg(result.orgs[0]);
      } else {
        navigate("/org-setup/");
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  setActiveOrg(org) {
    let reqs = [fetch(`/api/org/${org.id}`).then((r) => r.json()).then((org_info) => {
      let state = {active_org: org};
      state["payment"] = org_info.payment.brand || "Not On File";
      state["plan"] = org_info.plan;
      state["target_plan"] = org_info.plan;
      state["usage"] = org_info.usage;
      state["days_left_in_cycle"] = parseInt((new Date(org_info.cycle_end) - new Date()) / 1000 / 24 / 60/ 60);
      state["keys"] = org_info.tokens;
      state["referral_code"] = org_info.referral_code;
      console.log(state)
      this.setState(state)
    }),
    fetch(`/api/org/${org.id}/billing`).then((r) => r.json()).then((billing_info) => {
      let state = {};
      state["metered_cost"] = billing_info.cost/100;
      state["next_tier"] = billing_info.next_tier;
      state["credits"] = billing_info.available_credits;
      if(billing_info.rate !== 0) {
        state["rate"] = `${billing_info.rate/100} / 100k requests`
      } else {
        state["rate"] = "Free";
      }
      this.setState(state)
    }),
    fetch(`/api/org/${org.id}/subs`).then((r) => r.json()).then((data) => {
      console.log(data)
      let all_subscriptions = {};
      for(let sub of data.subscribed) {
        all_subscriptions[sub.id] = sub;
      }
      for(let sub of data.unsubscribed) {
        all_subscriptions[sub.id] = sub;
      }
      this.setState({subscribed: data.subscribed, unsubscribed: data.unsubscribed, all_subscriptions: all_subscriptions})
    })];
    Promise.all(reqs).then(() => {
      console.log(this.state)
      let hasDisplayed = false;
      if(typeof window !== 'undefined' && window) {
        hasDisplayed = !!window.localStorage.getItem("confirmedCryptoCredits");
      }
      if(!hasDisplayed && ((this.state.plan == "CRYPTO" && this.state.credits < 100000) || this.state.plan == "BUIDL" && this.state.credits + 3000000 - this.state.usage < 100000)) {
        this.setState({"directToCredits": true})
      }
    })
  }
  render() {

    const handleChange = (e) => {
      let state = {};
      state[e.target.name] = e.target.value;
      this.setState(state)
    }
    const handleSubmit = (e) => {
      e.preventDefault();
      let invite_code = Utils.getQueryVariable(this.props.location, "i")
      let referral_code = Utils.getQueryVariable(this.props.location, "r")
      navigate(`/${this.state.plan}/?i=${invite_code}&r=${referral_code}`)
    }
    const togglePlan = (subscription) => {
      return (e) => {
        console.log(subscription, e);
        console.log(e.target.checked);
        if(!e.target.checked) {
          this.setState({cancellation: {name: subscription.name, description: subscription.cancellation_description, id: subscription.id }});
        } else {
          this.setState({doSubscribe: {name: subscription.name, description: subscription.billing_description, id: subscription.id }})
        }
      }
    }
    let cardInfo;
    if(this.state.payment != "Not On File") {
      cardInfo = <AniLink fade to={`/paymethod/?org=${this.state.active_org.id}`} className={styles.buyCreditsLink}>Update Credit Card on File</AniLink>
    } else {
      cardInfo = <AniLink fade to={`/paymethod/?org=${this.state.active_org.id}`} className={styles.buyCreditsLink}>Add a Credit Card</AniLink>
    }
    let cancelPopup = undefined;
    if(this.state.cancellation) {
      let clear = () => {
        this.setState({cancellation: undefined});
      }
      let confirm = () => {
        fetch(`/api/org/${this.state.active_org.id}/subs/${this.state.cancellation.id}`, {
          method: "DELETE",
          headers: {
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        }).then((result) => {
          this.state.all_subscriptions[this.state.cancellation.id].cancelled = true;
          this.state.all_subscriptions[this.state.cancellation.id].expires = result.expires;
          this.setState({cancellation: undefined});
        })


      }
      cancelPopup = (
        <div key="wrapper" className={styles.offerWrapper}><div className={styles.offer}>
          <div className={styles.headWrapper}><h2>Cancel {this.state.cancellation.name}</h2></div>
          <span dangerouslySetInnerHTML={{__html:this.state.cancellation.description}}></span>
          <div className={styles.buttonWrapper}><button onClick={confirm} className={styles.submit}>Unsubscribe</button>
          <button onClick={clear} className={styles.submit}>Go Back</button></div>
          </div>
        </div>
      )
    }
    let confirmPopup
    if(this.state.doSubscribe) {
      let clear = () => {
        this.setState({doSubscribe: undefined});
      }
      let confirm = () => {
        if(this.state.confirming) { return }
        this.setState({confirming: true});
        fetch(`/api/org/${this.state.active_org.id}/subs/${this.state.doSubscribe.id}`, {
          method: "POST",
          headers: {
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        }).then((result) => {
          if(typeof(dataLayer) == 'object') {
            dataLayer.push({'event': 'subscribe-plan'});
          }
          this.state.all_subscriptions[this.state.doSubscribe.id].cancelled = false;
          this.state.all_subscriptions[this.state.doSubscribe.id].subscribed = true;
          this.state.all_subscriptions[this.state.doSubscribe.id].expires = undefined;
          this.setState({doSubscribe: undefined, confirming: undefined});
        })


      }
      confirmPopup = (
        <div key="wrapper" className={styles.offerWrapper}><div className={styles.offer}>
          <div className={styles.headWrapper}><h2>Subscribe To {this.state.doSubscribe.name}</h2></div>
          {this.state.doSubscribe.description}
          <div className={styles.buttonWrapper}><button disable={this.state.confirming} onClick={confirm} className={styles.submit}>Confirm</button>
          <button onClick={clear} className={styles.submit}>Go Back</button></div>
          </div>
        </div>
      )
    }

    let plans = [];
    for(let subscription of this.state.subscribed) {
      let permissions = [];
      if(subscription.permissions.length) {
        permissions.push(<dt className={styles.descriptionSubheading}>Permissions</dt>);
      }
      for(let permission of subscription.permissions) {
        permissions.push(<dd key={permission.name} className={styles.planPermission}><strong>{permission.name}</strong>: {permission.description}</dd>)
      }
      let toggle = (
        <label className={styles.switch}>
        <input onChange={togglePlan(subscription)} disabled={subscription.cancellable ? false : true} checked={!(subscription.cancelled || subscription.expires)} type="checkbox" />
        <span title={subscription.cancellable ? undefined : "Plan cannot be cancelled"} className={styles.slider}></span>
        </label>
      )
      let expires;
      if(subscription.expires) {
        console.log(subscription.name, "expires", subscription.expires.toString())
        expires = [
          <dt className={styles.descriptionSubheading}>Expiration Date</dt>,
          <dd>{new Date(subscription.expires * 1000).toLocaleDateString()}</dd>
        ];
      }
      let requests;
      if(subscription.limit > 0) {
        if(subscription.limit < 9000000000000000000) {
          requests = [
            <dt className={styles.descriptionSubheading}>Requests</dt>,
            <dd>{subscription.limit.toLocaleString()}</dd>
          ]
        } else {
          requests = [
            <dt className={styles.descriptionSubheading}>Requests</dt>,
            <dd>&infin;</dd>
          ]
        }
      } else {
        requests = undefined;
      }
      plans.push(<div key={"break" + subscription.name} className={styles.break}></div>);
      plans.push(
        <div key={subscription.name} className={styles.keyBoxBox}>
          { toggle }
          <h4 className={styles.planHeader}>{subscription.name}</h4>
          <div className={styles.descriptionContainer}><dl className={styles.planDescription}><dd dangerouslySetInnerHTML={{__html:subscription.description}}></dd>
          {permissions}
          {requests}
          {expires}
          </dl></div>
        </div>
      );
    }
    for(let subscription of this.state.unsubscribed) {
      let permissions = [];
      if(subscription.permissions.length) {
        permissions.push(<dt className={styles.descriptionSubheading}>Permissions</dt>);
      }
      for(let permission of subscription.permissions) {
        permissions.push(<dd key={permission.name} className={styles.planPermission}><strong>{permission.name}</strong>: {permission.description}</dd>)
      }
      let toggle = (
        <label className={styles.switch}>
        <input onChange={togglePlan(subscription)} disabled={!subscription.eligible} checked={subscription.subscribed ? true : false} type="checkbox" />
        <span title={subscription.eligible ? undefined : "You are not eligible for this plan"} className={styles.slider}></span>
        </label>
      )
      let requests;
      if(subscription.limit > 0) {
        if(subscription.limit < 9000000000000000000) {
          requests = [
            <dt className={styles.descriptionSubheading}>Requests</dt>,
            <dd>{subscription.limit.toLocaleString()}</dd>
          ]
        } else {
          requests = [
            <dt className={styles.descriptionSubheading}>Requests</dt>,
            <dd>&infin;</dd>
          ]
        }
      }
      let eligibility;
      if(!subscription.eligible) {
        eligibility = [<dt className={styles.descriptionSubheading}>Eligibility</dt>];
        if(subscription.requirement == "CARD") {
          eligibility.push(<dd><AniLink fade to={`/paymethod/?org=${this.state.active_org.id}`} >Add a Credit Card</AniLink></dd>);
        } else if (subscription.requirement == "EMAIL") {
          eligibility.push(<dd>Requires a confirmed email address</dd>);
        }
      }
      plans.push(<div key={"break" + subscription.name} className={styles.break}></div>);
      plans.push(
        <div key={subscription.name} className={styles.keyBoxBox}>
          { toggle }
          <h4 className={styles.planHeader}>{subscription.name}</h4>
          <div className={styles.descriptionContainer}><dl className={styles.planDescription}><dd dangerouslySetInnerHTML={{__html:subscription.description}}></dd>
            {permissions}
          {requests}
          {eligibility}
          </dl></div>
        </div>
      );
    }


    return (
      <section className={styles.dashboard}>
        <article className={styles.accountBox}>
          <h2 className={styles.sectionHeader}>Overview</h2>
            <ul className={styles.boxContent}>
              <li>Usage: {this.state.usage}</li>
              <li style={{display: !this.state.credits ? "none" : "list-item"}}>Credits: {this.state.credits}</li>
              <li>Days Left in Cycle: {this.state.days_left_in_cycle}</li>
              <li>Accrued Charges: ${this.state.metered_cost}</li>
              <li>{cardInfo}</li>
              <li><AniLink fade to={`/buy-credits/?org=${this.state.active_org.id}`} className={styles.buyCreditsLink}>Buy Request Credits with Crypto</AniLink></li>
            </ul>
        </article>
        <div className={styles.break}></div>
        <article className={styles.row1}>
          <h2 className={styles.sectionHeader}>Plans</h2>
          {plans}
        </article>
        {cancelPopup}
        {confirmPopup}
      </section>
    )
  }
}

export default UpdatePlan
